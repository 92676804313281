import React, { useEffect, useState } from "react";
import CustomChart from "./CustomChart";
import styles from "./MonitoringComponents.module.scss";
import axios from "axios";
import { convertUnixToJs } from "./Helper";
import moment from "moment";
import { tableNames } from "./Constants";

function ChartWidget(props) {
  const { assetValue } = props;
  // --- Line Chart ---
  // Start and End Time Filter
  const [endPicker, setEndPicker] = useState(moment());
  // Line Chart Data
  const [lineChartData, setLineChartData] = useState([]);
  async function getLineChartDataWrapper(selectedValue) {
    const currentPower = await axios.get(
      "/assets/getLineChartDataAdmin" +
        "?endTime=" +
        endPicker.unix() +
        "&assetName=" +
        tableNames[assetValue.value]
    );

    if (currentPower.status === 200 && selectedValue === "The Pulse") {
      const resultArray = currentPower.data.map((obj) => {
        return {
          "The Pulse": Number(obj["The Pulse"] / 1000).toFixed(2),
          jsTime: convertUnixToJs(obj.batch),
        };
      });
      setLineChartData(resultArray);
      return true;
    } else if (currentPower.status === 200 && selectedValue === "Asiagreen") {
      const resultArray = currentPower.data.map((obj) => {
        return {
          msb1: Number(obj["msb1_value"]).toFixed(2),
          msb2: Number(obj["msb2_value"]).toFixed(2),
          total: (Number(obj["msb1_value"])+Number(obj["msb2_value"])).toFixed(2),
          jsTime: convertUnixToJs(obj.batch),
        };
      });
      setLineChartData(resultArray);
      return true;
    } else if (
      currentPower.status === 200 &&
      selectedValue === "Enterprise One"
    ) {
      const resultArray = currentPower.data.map((obj) => {
        return {
          msb1: Number(obj["msb1_value"] / 1000).toFixed(2),
          msb2: Number(obj["msb2_value"] / 1000).toFixed(2),
          msb3: Number(obj["msb3_value"] / 1000).toFixed(2),
          msb4: Number(obj["msb4_value"] / 1000).toFixed(2),
          msb5: Number(obj["msb5_value"] / 1000).toFixed(2),
          msb6: Number(obj["msb6_value"] / 1000).toFixed(2),
          msb7: Number(obj["msb7_value"] / 1000).toFixed(2),
          msb8: Number(obj["msb8_value"] / 1000).toFixed(2),
          msb9: Number(obj["msb9_value"] / 1000).toFixed(2),
          msb10: Number(obj["msb10_value"] / 1000).toFixed(2),
          total: Number(obj["total"] / 1000).toFixed(2),
          jsTime: convertUnixToJs(obj.batch),
        };
      });
      setLineChartData(resultArray);
      return true;
    }
    return false;
  }

  useEffect(() => {
    const result = setInterval(async () => {
      try {
        const powerFetch = await getLineChartDataWrapper(assetValue.value);
        if (powerFetch) {
          setEndPicker(moment());
        }
      } catch (error) {
        console.error(error);
      }
    }, 30000);
    return () => {
      clearInterval(result);
    };
  }, [endPicker, assetValue.value]);
  useEffect(() => {
    getLineChartDataWrapper(assetValue.value);
  }, [assetValue.value]);

  // ----- End of Line Chart -----
  return (
    <>
      <div className={styles.FilterHeaderRow}>
        <div className={styles.HeaderTimeFilter}>
          <div for="monitorTime">
            Last Updated:{" "}
            <b>{moment(endPicker).format("dddd, MMMM Do YYYY, h:mm:ss a")}</b>
          </div>
        </div>
      </div>
      <div className={styles.LineChartContainer}>
        {lineChartData.length > 0 ? (
          <CustomChart
            lineChartData={lineChartData}
            selectedValue={assetValue.value}
          />
        ) : null}
      </div>
    </>
  );
}

export default ChartWidget;
