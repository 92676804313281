import React, { useState } from "react";
import Widget from "../../components/Widget/Widget.js";
import Timeline from "./components/MonitoringComponents/Timeline.js";
import Table from "./components/MonitoringComponents/BatteryTable.js";
import ChargeSettings from "./components/MonitoringComponents/ChargeSettings.js";
import ChartWidget from "./components/MonitoringComponents/ChartWidget.js";
import CustomChartAHU from "./components/MonitoringComponents/CustomChartAHU.js";
import CustomChartSOLAR from "./components/MonitoringComponents/CustomChartSOLAR.js";
import styles from "./Monitoring.module.scss";
import PowerConsumptionWidget from "./components/MonitoringComponents/PowerConsumptionWidget.js";
import { useSelector } from "react-redux";
import Select from "react-select";
import ErrorsTimeline from "./components/MonitoringComponents/ErrorsTimeline.js";
import {
  assetOptions,
  batteryOptions,
} from "./components/MonitoringComponents/Constants.js";
function Monitoring() {
  const store = useSelector((state) => state.auth);
  // Multi Select States
  const [assetValue, setAssetValue] = useState({
    value: "The Pulse",
    label: "The Pulse",
    color: "black",
  });
  // ----- Battery Options -----
  const [selectedBattery, setSelectedBattery] = useState({
    value: batteryOptions[assetValue.value][0]["value"],
    label: batteryOptions[assetValue.value][0]["value"],
  });

  return (
    <div>
      <div className={styles.MonitoringAssetOptionContainer}>
        <Select
          defaultValue={assetValue}
          options={assetOptions}
          onChange={setAssetValue}
        />
      </div>
      <div className={styles.WidgetContainer}>
        <Widget title="Live Power Consumption">
          <ChartWidget assetValue={assetValue} />
        </Widget>
      </div>

      {assetValue.value !== "Enterprise One" ? (
        <div className={styles.WidgetContainer}>
          <Widget
            title="Power Consumption Table "
            className={styles.PowerConsumptionTableContainer}
          >
            <PowerConsumptionWidget
              assetValue={assetValue}
              selectedBattery={selectedBattery}
              setSelectedBattery={setSelectedBattery}
            />
          </Widget>
        </div>
      ) : null}
      {store?.currentUser?.role === "admin" ? (
        <div className={styles.WidgetContainer}>
          <Widget title="Charge Settings">
            <ChargeSettings
              assetValue={assetValue}
              selectedBattery={selectedBattery}
              setSelectedBattery={setSelectedBattery}
            />
          </Widget>
        </div>
      ) : null}

      <div className={styles.WidgetContainer}>
        <Widget title="Battery Data" className={styles.BatteryTableContainer}>
          <Table
            assetValue={assetValue}
            selectedBattery={selectedBattery.value}
          />
        </Widget>
      </div>
      <div className={styles.WidgetContainer}>
        <Widget
          title="AHU Data (demo)"
          className={styles.BatteryTableContainer}
        >
          <CustomChartAHU />
        </Widget>
      </div>
      <div className={styles.WidgetContainer}>
        <Widget
          title="Photovaltics Generation Data (demo)"
          className={styles.BatteryTableContainer}
        >
          <CustomChartSOLAR />
        </Widget>
      </div>
      <div className={styles.WidgetContainer}>
        <Widget title="Actions" className={styles.TimelineTableContainer}>
          <Timeline assetValue={assetValue} selectedBattery={selectedBattery} />
        </Widget>
      </div>
      <div className={styles.WidgetContainer}>
        <Widget title="Errors" className={styles.TimelineTableContainer}>
          <ErrorsTimeline
            assetValue={assetValue}
            selectedBattery={selectedBattery}
          />
        </Widget>
      </div>
    </div>
  );
}

export default Monitoring;
