import { combineReducers } from "redux";
import auth from "./auth.js";
import navigation from "./navigation.js";
// import register from "./register.js";
import offers from "./offer.js";
import bids from "./bid.js";
import units from "./unit.js";
import groups from "./group.js";
import buildings from "./building.js";
import facilities from "./facility.js";
import messages from "./maintenance.js";
import users from "./usersReducers";
import { connectRouter } from "connected-react-router";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    navigation,
    offers,
    bids,
    users,
    units,
    groups,
    buildings,
    facilities,
    messages,
  });
