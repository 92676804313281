// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Monitoring_MonitoringAssetOptionContainer__2Ku1r {\n  width: 30%;\n  margin-bottom: 20px; }\n\n.Monitoring_WidgetContainer__11yxB {\n  margin-bottom: 20px;\n  min-height: -moz-fit-content;\n  min-height: fit-content; }\n\n.Monitoring_PowerConsumptionTableContainer__3rDsd {\n  padding: 0rem 2rem 3rem 2rem;\n  height: 700px; }\n\n.Monitoring_TimelineTableContainer__3bebR {\n  padding: 0rem 2rem 3rem 2rem;\n  min-height: 630px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between; }\n\n.Monitoring_BatteryTableContainer__1tBiT {\n  padding: 0rem 2rem 3rem 2rem; }\n", "",{"version":3,"sources":["webpack://src/pages/tables/Monitoring.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,mBAAmB,EAAA;;AAGrB;EACE,mBAAmB;EACnB,4BAAuB;EAAvB,uBAAuB,EAAA;;AAIzB;EACE,4BAA4B;EAC5B,aAAa,EAAA;;AAEf;EACE,4BAA4B;EAC5B,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,8BAA8B,EAAA;;AAEhC;EACE,4BAA4B,EAAA","sourcesContent":[".MonitoringAssetOptionContainer {\n  width: 30%;\n  margin-bottom: 20px;\n}\n\n.WidgetContainer {\n  margin-bottom: 20px;\n  min-height: fit-content;\n}\n\n// Table\n.PowerConsumptionTableContainer {\n  padding: 0rem 2rem 3rem 2rem;\n  height: 700px;\n}\n.TimelineTableContainer {\n  padding: 0rem 2rem 3rem 2rem;\n  min-height: 630px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.BatteryTableContainer {\n  padding: 0rem 2rem 3rem 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MonitoringAssetOptionContainer": "Monitoring_MonitoringAssetOptionContainer__2Ku1r",
	"WidgetContainer": "Monitoring_WidgetContainer__11yxB",
	"PowerConsumptionTableContainer": "Monitoring_PowerConsumptionTableContainer__3rDsd",
	"TimelineTableContainer": "Monitoring_TimelineTableContainer__3bebR",
	"BatteryTableContainer": "Monitoring_BatteryTableContainer__1tBiT"
};
export default ___CSS_LOADER_EXPORT___;
