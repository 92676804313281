// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes PredictionComponents_flash__27KXZ {\n  0% {\n    background-color: gold; }\n  100% {\n    background-color: transparent; } }\n\n.PredictionComponents_flash__27KXZ {\n  animation: PredictionComponents_flash__27KXZ 2s ease-in-out; }\n", "",{"version":3,"sources":["webpack://src/pages/tables/components/PredictionComponents/PredictionComponents.module.scss"],"names":[],"mappings":"AAAA;EACE;IAAK,sBAAsB,EAAA;EAC3B;IAAO,6BAA6B,EAAA,EAAA;;AAGtC;EACE,2DAA+B,EAAA","sourcesContent":["@keyframes flash {\n  0% { background-color: gold; }\n  100% { background-color: transparent; }\n}\n\n.flash {\n  animation: flash 2s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flash": "PredictionComponents_flash__27KXZ"
};
export default ___CSS_LOADER_EXPORT___;
