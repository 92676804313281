import React, { useEffect, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CustomChart(props) {
  const { lineChartData, selectedValue } = props;
  const totalNumData = lineChartData.length;
  let xAxisTime = [
    lineChartData[0]["jsTime"],
    lineChartData[Math.ceil(totalNumData / 6)]["jsTime"],
    lineChartData[Math.ceil((2 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((3 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((4 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((5 * totalNumData) / 6)]["jsTime"],
    lineChartData[totalNumData - 1]["jsTime"],
  ];

  let maxValue = 0;
  if (selectedValue === "The Pulse") {
    maxValue = Math.max(...lineChartData.map((o) => o[selectedValue]));
  } else if (selectedValue === "Asiagreen") {
    maxValue = Math.max(
      ...lineChartData.map((o) => o["msb1"]),
      ...lineChartData.map((o) => o["msb2"]),
      ...lineChartData.map((o) => o["total"])
    );
  } else if (selectedValue === "Enterprise One") {
    maxValue = Math.max(...lineChartData.map((o) => o["total"]));
  }

  function lineData() {
    if (selectedValue === "Asiagreen") {
      return [
        { dataKey: "msb1", stroke: "#FF5668" },
        { dataKey: "msb2", stroke: "#82ca9d" },
      ];
    } else if (selectedValue === "Enterprise One") {
      return [
        { dataKey: "msb1", stroke: "#FF5733" },
        { dataKey: "msb2", stroke: "#33FF57" },
        { dataKey: "msb3", stroke: "#5733FF" },
        { dataKey: "msb4", stroke: "#FF33A1" },
        { dataKey: "msb5", stroke: "#33A1FF" },
        { dataKey: "msb6", stroke: "#A1FF33" },
        { dataKey: "msb7", stroke: "#FF8C33" },
        { dataKey: "msb8", stroke: "#8C33FF" },
        { dataKey: "msb9", stroke: "#33FF8C" },
        { dataKey: "msb10", stroke: "#8CFF33" },
        { dataKey: "total", stroke: "black" },
      ];
    } else if (selectedValue === "The Pulse") {
      return [{ dataKey: "The Pulse", stroke: "#8884d8" }];
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={lineChartData}
        margin={{
          top: 5,
          right: 55,
          left: 45,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="jsTime" ticks={xAxisTime} />
        <YAxis dataKey={selectedValue} unit={"kW"} domain={[0, maxValue]} />

        <Tooltip />
        <Legend />
        {lineData().map((line, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={line.dataKey}
            stroke={line.stroke}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
