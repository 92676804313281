import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import styles from "./Modal.module.scss";
import { addTimeToDate } from "../Helper";
import { thingNames } from "../Constants";

export default function DischargeModal(props) {
  const {
    toggleDischargeModal,
    dischargeModal,
    startDischargeDateTime,
    dischargeDuration,
    dischargeRate,
    setBatteryDetails,
    batteryName,
  } = props;

  async function handleConfirmDischarge() {
    // Close modal
    toggleDischargeModal();

    const message = {
      thingName: thingNames[batteryName],
      startDischargeDateTime: startDischargeDateTime,
      dischargeDuration: dischargeDuration,
      dischargeRate: dischargeRate,
    };
    const publishDischargeSettings = await axios.post(
      "/batteries/publishDischargeSettings",
      message
    );
    const response = await axios.get("/charge_settings");
    if (response.status === 200) {
      setBatteryDetails(response.data);
    }
  }

  return (
    <Modal isOpen={dischargeModal} toggle={toggleDischargeModal}>
      <ModalHeader toggle={toggleDischargeModal}>
        Confirm Discarge of {batteryName}?
      </ModalHeader>
      <ModalBody>
        <div className={styles.ChargeSettingsFieldGroup}>
          <div for="endDischargeTime">Calculated End Charge Time: </div>
          <div>{addTimeToDate(startDischargeDateTime, dischargeDuration)}</div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleConfirmDischarge}>
          Confirm Discharge
        </Button>
      </ModalFooter>
    </Modal>
  );
}
