import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Slider from "@material-ui/core/Slider";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import "rc-color-picker/assets/index.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import "react-datepicker/dist/react-datepicker.css";

import { addBid } from "../../../actions/bid";
import { fetchFacilities } from "../../../actions/facility";

import Widget from "../../../components/Widget/Widget";
import s from "./Elements.module.scss";
import periods from "./periods";

export default function Elements() {
  const store = useSelector((state) => state.facilities);
  useEffect(() => {
    dispatch(fetchFacilities());
  }, []);

  const state = {
    // selectFacilities: [{ value: 1, label: "CLEAR : CRYSTAL", max_mw: 5 }],
    selectFacilities: store.facilities.map((facility) => {
      return {
        value: facility.id,
        label: facility.name,
        max_mw: parseFloat(facility.emc_max_rating_2),
      };
    }),
    selectPeriods: periods.periods,
  };
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  // const store = useSelector(state => state.offers)

  const [bidType, setBidType] = useState("variation");
  const [bidDate, setBidDate] = useState(new Date());
  const [facility, setFacility] = useState("");
  const [maxMW, setMaxMW] = useState(0);
  const [loading, setLoading] = useState(false);

  const [periodPairs, setPeriodPairs] = useState([
    {
      periodFrom: 1,
      periodTo: 2,
      qp: [
        { q: 0, p: 0, s: 0, error: null },
        { q: 0, p: 0, s: [0, 0], error: null },
        { q: 0, p: 0, s: [0, 0], error: null },
        { q: 0, p: 0, s: [0, 0], error: null },
        { q: 0, p: 0, s: [0, 0], error: null },
      ],
    },
  ]);

  const updatePeriodFrom = (data, index) => {
    setPeriodPairs((prevState) => {
      const newPeriodPairs = [...prevState];
      newPeriodPairs[index].periodFrom = data.value;
      return newPeriodPairs;
    });
  };

  const updatePeriodTo = (data, index) => {
    setPeriodPairs((prevState) => {
      const newPeriodPairs = [...prevState];
      newPeriodPairs[index].periodTo = data.value;
      return newPeriodPairs;
    });
  };

  const handleChangeForSlider = useCallback(
    (newValue, periodIndex, qpIndex) => {
      setPeriodPairs((prevState) => {
        const newPeriodPairs = [...prevState];
        newPeriodPairs[periodIndex].qp[qpIndex] = {
          ...newPeriodPairs[periodIndex].qp[qpIndex],
          s: newValue,
          q:
            qpIndex === 0
              ? Math.floor(((maxMW * newValue) / 100) * 10) / 10
              : newValue[1] === 100 
		? Math.round(((maxMW * (newValue[1] - newValue[0])) / 100) * 10) / 10
		: Math.floor(((maxMW * (newValue[1] - newValue[0])) / 100) * 10) / 10,
        };

        for (let index = qpIndex + 1; index <= 4; index++) {
          newPeriodPairs[periodIndex].qp[index] = {
            ...newPeriodPairs[periodIndex].qp[index],
            s:
              newValue.length > 1
                ? [newValue[1], newValue[1]]
                : [newValue, newValue],
          };
        }

        return newPeriodPairs;
      });
    },
    [maxMW]
  );

  const handleChangeForPrice = useCallback((newValue, periodIndex, qpIndex) => {
    setPeriodPairs((prevState) => {
      const newPeriodPairs = [...prevState];
      newPeriodPairs[periodIndex].qp[qpIndex] = {
        ...newPeriodPairs[periodIndex].qp[qpIndex],
        p: newValue,
      };
      return newPeriodPairs;
    });
  }, []);

  // const addQPPair = (periodIndex) => {
  //   console.log(periodIndex);
  //   let oldPeriodPairs = periodPairs;
  //   oldPeriodPairs[periodIndex].qp =
  //     [
  //       ...oldPeriodPairs[periodIndex].qp,
  //       { q: 0, p: 0, s: 0 }
  //     ]

  //   console.log(oldPeriodPairs);
  //   setPeriodPairs(oldPeriodPairs, {force: true});
  // }

  // const addPeriod = () => {
  //   setPeriodPairs([
  //     ...periodPairs,
  //     {
  //       period: 1,
  //       qp: [
  //         { q: 0, p: 0, s: 0 },
  //         { q: 0, p: 0, s: 0 },
  //         { q: 0, p: 0, s: 0 },
  //         { q: 0, p: 0, s: 0 },
  //         { q: 0, p: 0, s: 0 }
  //       ]
  //     }
  //   ])
  // }

  const handleAddBid = async () => {
    let hasError = false;
    setPeriodPairs((prevState) => {
      const newPeriodPairs = [...prevState];
      let previousPrice = 0;
      for (let index = 0; index < newPeriodPairs[0].qp.length; index++) {
        const currentElement = newPeriodPairs[0].qp[index];
        if (index === 0) {
          previousPrice = currentElement.p;
        } else {
          if (
            currentElement.p !== 0 &&
            parseFloat(previousPrice) <= parseFloat(currentElement.p)
          ) {
            // has error
            newPeriodPairs[0].qp[index] = {
              ...newPeriodPairs[0].qp[index],
              error: "This price must be lower than the previous price.",
            };
            hasError = true;
          } else {
            previousPrice = currentElement.p;
          }
        }
      }

      // go ahead to submit only if there is no error
      if (!hasError) {
        setLoading(true);
        const obj = {
          bidType,
          bidDate: moment(bidDate).format("DD/MM/YYYY"),
          facility,
          periodPairs,
        };

        dispatch(addBid(obj));
      }

      return newPeriodPairs;
    });
  };

  const getAriaValueText = useCallback(
    (periodIndex, pair_index) => {
      let quantity = periodPairs[periodIndex].qp[pair_index].q;
      return `${quantity}MW`;
    },
    [periodPairs]
  );

  const renderQuantityPricePairs = useCallback(
    (qpArray, periodIndex) => {
      return qpArray.map((pair, pair_index) => {
        return (
          <div key={`${periodIndex}_${pair_index}`}>
            <FormGroup row>
              <Label md="2" for="default-select">
                Quantity {pair_index + 1} (
                {getAriaValueText(periodIndex, pair_index)})
              </Label>
              <Col md="4" className={s.select}>
                <Slider
                  defaultValue={
                    pair_index === 0
                      ? 0
                      : periodPairs[periodIndex].qp[pair_index].s
                  }
                  onChange={(_, newValue) =>
                    handleChangeForSlider(newValue, periodIndex, pair_index)
                  }
                  valueLabelDisplay="auto"
                  value={periodPairs[periodIndex].qp[pair_index].s}
                  aria-labelledby="range-slider"
                  step={5}
                />
              </Col>
              {/* </FormGroup>
          <FormGroup row> */}
              <Label md={2} for="normal-field">
                Price {pair_index + 1}
              </Label>
              <Col md={4}>
                <Input
                  type="text"
                  id="normal-field"
                  placeholder="0.00"
                  onChange={(event) =>
                    handleChangeForPrice(
                      event.target.value,
                      periodIndex,
                      pair_index
                    )
                  }
                />
                {periodPairs[periodIndex].qp[pair_index].error ? (
                  <div className={"help-block text-danger label"}>
                    {periodPairs[periodIndex].qp[pair_index].error}
                  </div>
                ) : null}
              </Col>
            </FormGroup>
          </div>
        );
      });
    },
    [getAriaValueText, handleChangeForPrice, handleChangeForSlider, periodPairs]
  );

  return (
    <div>
      <Form onSubmit={handleSubmit(handleAddBid)}>
        <Row>
          <Col>
            <Row className="gutter mb-4">
              <Col xs={12} md={8}>
                <Widget className="widget-p-md">
                  <div className="headline-2 mb-3">Add Bid</div>
                  <Row>
                    <Col md="6">
                      <div className="mb-2">Submission Type</div>
                      {/*<FormGroup className="radio abc-radio">
                        <Input
                          type="radio"
                          id="bid_type_standing"
                          value="standing"
                          name="bidType"
                          onChange={(e) => setBidType(e.target.value)}
                          checked={bidType === "standing"}
                        />
                        <Label for="bid_type_standing">Standing</Label>
                      </FormGroup>*/}
                      <FormGroup className="radio abc-radio">
                        <Input
                          type="radio"
                          id="bid_type_variation"
                          value="variation"
                          name="bidType"
                          onChange={(e) => setBidType(e.target.value)}
                          checked={bidType === "variation"}
                        />
                        <Label for="bid_type_variation">Variation</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup row>
                        <Label md="4">Date</Label>
                        {/* <div className="mb-2">Date</div> */}
                        <DatePicker
                          dateformat="dd/MM/yyyy"
                          selected={bidDate}
                          onChange={(date) => setBidDate(date)}
                          placeholderText="Please select a date!"
                        />
                      </FormGroup>
                    </Col>

                    {/* </Row>
                <Row className="mt-2"> */}
                    <Col md="6">
                      <FormGroup row>
                        <Label md="4">Facility</Label>
                        <Col md="8" className={s.select}>
                          <Select
                            options={state.selectFacilities}
                            onChange={(data) => {
                              setFacility(data.value);
                              setMaxMW(data.max_mw);
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Widget>
              </Col>
            </Row>
            {periodPairs.map((periodPair, index) => {
              return (
                <Row className="gutter mb-4" key={index}>
                  <Col xs={12} md={12} className="mt-4 mt-md-0">
                    <Widget className="widget-p-md">
                      <div className="headline-2 mb-4 mt-4">
                        Max MW - {maxMW}
                      </div>
                      <div className="headline-2">Period</div>
                      <FormGroup row>
                        <Label md="2" for="default-select">
                          From Period
                        </Label>
                        <Col md="4" className={s.select}>
                          <Select
                            options={state.selectPeriods}
                            defaultValue={state.selectPeriods[0]}
                            onChange={(data) => updatePeriodFrom(data, index)}
                          />
                        </Col>
                        <Label md="2" for="default-select">
                          To Period
                        </Label>
                        <Col md="4" className={s.select}>
                          <Select
                            options={state.selectPeriods}
                            defaultValue={state.selectPeriods[1]}
                            onChange={(data) => updatePeriodTo(data, index)}
                          />
                        </Col>
                      </FormGroup>
                      {renderQuantityPricePairs(periodPair.qp, index)}
                      {/* <Button outline color="primary" onClick={() => addQPPair(index)} className="mr-3 mt-3">Add Quantity / Price Pair</Button> */}
                    </Widget>
                  </Col>
                </Row>
              );
            })}
            {/* <Row className="gutter mb-4">
            <Col xs={12} md={6} className="mt-4 mt-md-0">
              <Button outline color="primary" onClick={addPeriod} className="mr-3 mt-3">Add Period</Button>
            </Col>
          </Row> */}
            <Row className="gutter mb-4">
              <Col xs={12} md={6} className="mt-4 mt-md-0">
                <Button color="primary" type="submit" className="mr-3 mt-3">
                  {loading ? <Spinner size="sm" className="mr-3 mt-1" /> : null}
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
