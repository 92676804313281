import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  { hour: '09:00', ToGrid: 6, Consumed: 15 },
  { hour: '09:00', ToGrid: 9, Consumed: 19 },
  { hour: '10:00', ToGrid: 6, Consumed: 20 },
  { hour: '11:00', ToGrid: 3, Consumed: 25 },
  { hour: '12:00', ToGrid: 5, Consumed: 18 },
  { hour: '13:00', ToGrid: 4, Consumed: 23 },
  { hour: '14:00', ToGrid: 5, Consumed: 19 },
  { hour: '15:00', ToGrid: 7, Consumed: 22 },
  { hour: '16:00', ToGrid: 9, Consumed: 27 },

];

const StackedBarChart = () => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="hour" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ToGrid" stackId="a" fill="rgb(136, 243, 163)" barSize={150} />
        <Bar dataKey="Consumed" stackId="a" fill="rgb(237, 222, 22)" barSize={150} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
