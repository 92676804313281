import React, { useEffect, useState } from "react";
import axios from "axios";
import { convertUnixToJsWithDate } from "./Helper";
import moment from "moment";
import styles from "./MonitoringComponents.module.scss";
import DateRangePicker from "./DateRangePicker";
import PowerConsumptionTable from "./PowerConsumptionTable";
import { tableNames } from "./Constants";

export default function PowerConsumptionWidget(props) {
  const { assetValue } = props;
  const [startPicker, setStartPicker] = useState(
    moment().subtract("6", "h").toDate()
  );
  const [endPicker, setEndPicker] = useState(new Date());
  const [powerConsumptionTableData, setPowerConsumptionTableData] = useState(
    []
  );

  async function getPowerConsumptionTableDataWrapper(
    startTime,
    endTime,
    currentPage
  ) {
    const response = await axios.get(
      "/assets" +
        "/getPowerConsumptionTableData" +
        "?startTime=" +
        moment(startTime).unix() +
        "&endTime=" +
        moment(endTime).unix() +
        "&currentPage=" +
        currentPage +
        "&assetName=" +
        tableNames[assetValue.value]
    );

    if (response.status === 200) {
      console.log(response.data, "response data");
      const withJsTime = response.data.rows.map((obj) => {
        return {
          Asset1: (obj["asset"] / 1000).toFixed(2),
          jsTime: convertUnixToJsWithDate(obj["batch"]),
        };
      });

      setPowerConsumptionTableData(withJsTime);
      setTotalNumberOfRows(response.data.count);

      return true;
    }
    return false;
  }

  // pagination things
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(1);

  function maxPage(totalNumberOfRows) {
    if (totalNumberOfRows > 10) {
      return Math.ceil(totalNumberOfRows / 10);
    }

    return 1;
  }

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalNumberOfRows / 10)) {
      setCurrentPage(newPage);
      setInputPage(newPage);
    }
  };

  const handlePreviousPage = () => handlePageChange(currentPage - 1);

  const handleNextPage = () => handlePageChange(currentPage + 1);

  const handlePageInput = (e) => {
    if (e.key === "Enter" || e.type === "click") {
      handlePageChange(inputPage);
    }
  };

  useEffect(() => {
    getPowerConsumptionTableDataWrapper(startPicker, endPicker, currentPage);
  }, [currentPage, startPicker, endPicker, assetValue.value]);
  useEffect(() => {
    setCurrentPage(1);
    setInputPage(1);
  }, [startPicker, endPicker]);
  return (
    <div>
      <div className={styles.FilterHeaderRow}>
        <div className={styles.HeaderTimeFilter}>
          <div for="startTime">Start Time</div>
          <div>
            <DateRangePicker
              timePicker={startPicker}
              setTimePicker={setStartPicker}
              classes="col-md-10"
            />
          </div>
        </div>
        <div className={styles.HeaderTimeFilter}>
          <div for="endTime">End Time</div>
          <div>
            <DateRangePicker
              timePicker={endPicker}
              setTimePicker={setEndPicker}
              classes="col-md-10"
            />
          </div>
        </div>
      </div>
      <div>
        {powerConsumptionTableData.length !== 0 ? (
          <PowerConsumptionTable
            assetName={assetValue.value}
            powerConsumptionTableData={powerConsumptionTableData}
            currentPage={currentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            handlePageInput={handlePageInput}
            totalNumberOfRows={totalNumberOfRows}
            maxPage={maxPage}
            inputPage={inputPage}
            setInputPage={setInputPage}
          />
        ) : null}
      </div>
    </div>
  );
}
