import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import styles from "./MonitoringComponents.module.scss";
import { tableNames, months } from "./Constants";
import CustomBarChart from "./CustomBarChart";

export default function BatteryTable(props) {
  const { selectedBattery } = props;
  const [modal, setModal] = useState(false);

  function toggleModal() {
    setModal(!modal);
  }

  // ----- Table Data -----
  const [batteryDetails, setBatteryDetails] = useState({});
  async function getTableDataWrapper() {
    const response = await axios.get(
      `/batteries/getBatteryTableData?batteryTableName=${tableNames[selectedBattery]}`
    );

    if (response.status === 200) {
      setBatteryDetails(convertArrayToObjects(response.data));
    }
  }
  function convertArrayToObjects(array) {
    const objects = {};
    for (const item of array) {
      objects[item.tag] = item.value;
    }

    // convert SOC to actual capacity
    objects["SOC"] = ((objects["Average SOC (%)"] / 100) * 271).toFixed(2);
    //varPriority: 0 = nothing critical,
    objects["PriorityLevel"] = varPriority[objects["varPriority"]];
    return objects;
  }

  useEffect(() => {
    getTableDataWrapper();
  }, [selectedBattery]);

  let idleChargeDischarge = ["Idle", "Discharge", "Charge"];
  let varPriority = ["Non-Critical", "Critical"];
  // ----- End of Table Data -----
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>State of Charge</th>
            <th>Score</th>
            <th>Idle/Charging/Discharging</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{selectedBattery}</td>
            <td>
              {batteryDetails["Average SOC (%)"]}% - {batteryDetails["SOC"]}/271
              (kWh)
            </td>
            <td>{batteryDetails["score"]}</td>
            <td>{idleChargeDischarge[batteryDetails["CnD"]]}</td>

            <td>
              <Button color="primary" onClick={toggleModal}>
                View
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Details from PYC001BAT1</ModalHeader>
        <ModalBody>
          <div>
            <div className={styles.ModalFields}>
              <strong>Priority: </strong>
              <span>{batteryDetails["PriorityLevel"]}</span>
            </div>
            {/* todo -  this field is currently removed in the db */}
            {/* <div className={styles.ModalFields}>
              <strong>Days Last Serviced: </strong>
              <span>{batteryDetails["dls"]}</span>
            </div> */}

            <div className={styles.ModalFields}>
              <strong>Total Alarm Count: </strong>
              <span>{batteryDetails["totalCount"]}</span>
            </div>

            <div className={styles.ModalFields}>
              <strong>DLS Score: </strong>
              <span>{batteryDetails["dlsScore"]}</span>
            </div>

            <div className={styles.ModalFields}>
              <strong>Alarm Error Message: </strong>
              <span>
                {batteryDetails["alarmMessage"] === "{}"
                  ? "No Error"
                  : batteryDetails["alarmMessage"]}
              </span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
