import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  { month: 'Jul 2024', kWh: 26 },
  { month: 'Aug 2024', kWh: 18 },
  { month: 'Sep 2024', kWh: 25 },
  { month: 'Oct 2024', kWh: 21 },
  { month: 'Nov 2024', kWh: 20 },
  { month: 'Dec 2024', kWh: 30 },
];

const renderCustomAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} transform="rotate(-20)" textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

const MyLineChart = () => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tick={renderCustomAxisTick} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="linear" dataKey="kWh" stroke="#8884d8" activeDot={{ r: 8 }} strokeWidth={3} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MyLineChart;