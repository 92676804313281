import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import styles from "./Modal.module.scss";
import { addTimeToDate } from "../Helper";
import { thingNames } from "../Constants";

export default function ChargeModal(props) {
  const {
    toggleChargeModal,
    chargeModal,
    startChargeDateTime,
    chargeDuration,
    setBatteryDetails,
    batteryName,
  } = props;
  async function handleConfirmCharge() {
    // Close modal
    toggleChargeModal();

    // Send message to AWS IoT Core to charge
    const message = {
      thingName: thingNames[batteryName],
      startChargeDateTime: startChargeDateTime,
      chargeDuration: chargeDuration,
    };
    const publishChargeSettings = await axios.post(
      "/batteries/publishChargeSettings",
      message
    );
    const response = await axios.get("/charge_settings");
    if (response.status === 200) {
      setBatteryDetails(response.data);
    }
  }
  return (
    <Modal isOpen={chargeModal} toggle={toggleChargeModal}>
      <ModalHeader toggle={toggleChargeModal}>
        Confirm Charge of {batteryName}?
      </ModalHeader>
      <ModalBody>
        <div className={styles.ChargeSettingsFieldGroup}>
          <div for="endChargeTime">Calculated End Charge Time: </div>
          <div>{addTimeToDate(startChargeDateTime, chargeDuration)}</div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleConfirmCharge}>
          Confirm Charge
        </Button>
      </ModalFooter>
    </Modal>
  );
}
