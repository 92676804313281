import React from "react";
import { 
  BarChart,
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';    // Newly added
export default function CustomBarChart(props) {
  const { lineChartData} = props;
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        layout="vertical"   // newly added
        width={500}
        height={300}
        // data={testtest}
        data={lineChartData}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category"/>
        <Tooltip />
        <Legend />
        <Bar dataKey="charge" fill="rgb(253, 170, 62)" />
        <Bar dataKey="discharge" fill="rgb(243, 136, 136)" />
      </BarChart>
    </ResponsiveContainer>
  );
};
