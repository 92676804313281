import React, { useState, useEffect } from "react";
import Widget from "../../components/Widget/Widget.js";
import Yesterday from "./components/PredictionComponents/Yesterday";
import Today from "./components/PredictionComponents/Today";

const Prediction = function () {
  return (
    <div>
      <div className="mb-3">
        <Widget>
          <Yesterday />
        </Widget>
      </div>
      <div className="mb-3">
        <Widget>
          <Today />
        </Widget>
      </div>
    </div>
  );
};

export default Prediction;
