import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import moment from "moment";
import styles from "./PredictionComponents.module.scss";

const Today = () => {
  const [today, setToday] = useState([]);
  const [currentLoad, setCurrentLoad] = useState(0);
  const [flash, setFlash] = useState(false);
  const prevLoadRef = useRef(0);
  const firstPeriodRef = useRef(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  function getCurrentPeriod() {
    const now = moment();
    const startOfDay = now.clone().startOf('day');
    const minutesSinceStartOfDay = now.diff(startOfDay, 'minutes');
    return Math.floor(minutesSinceStartOfDay / 30) + 1;
  }

  async function getToday() {
    const response = await axios.get("/prediction/today", {
    });
    if (response.status === 200) {
      setToday(response.data);
    }
  }

  async function getCurrentLoad() {
    if (firstPeriodRef.current !== 0 && getCurrentPeriod() !== firstPeriodRef.current) {
      return;
    }

    const response = await axios.get("/prediction/current_load", {
    });
    if (response.status === 200) {
      if (response.data !== prevLoadRef.current) {
        setFlash(true);
        setTimeout(() => setFlash(false), 2000);
      }
      setCurrentLoad(response.data);
      prevLoadRef.current = response.data;
    }
  }

  useEffect(() => {
    getToday();
    getCurrentLoad();
    const interval = setInterval(() => {
      getCurrentLoad();
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const columns = [
    {
      name: "Period",
      label: "Period",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowIndex === 0) firstPeriodRef.current = value;
          return value;
        },
      },
    },
    {
      name: "whether_DR",
      label: "Type Prediction",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? "DR" : "IL";
        },
      },
    },
    {
      name: "loadCurtailmentPrice",
      label: "Type Actual",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value > 0 ? "DR" : "IL";
        },
      },
    },
    {
      name: "total_load",
      label: "Load Prediction (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value / 1000).toFixed(4);
        },
      },
    },
    {
      name: "actualLoad",
      label: "Load Actual (MW)",
      options: {
        filter: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const isFlashing = tableMeta.rowIndex === 0 && flash;
          return (
            <div className={`pt-1 pb-1 ${isFlashing ? styles.flash : ""}`} style={{ display: "inline" }}>
              {tableMeta.rowIndex === 0 && getCurrentPeriod() === firstPeriodRef.current && currentLoad !== 0
                ? (
                  <span>
                    {(currentLoad / 1000).toFixed(4)}
                    <i className="ml-1 eva eva-sync" style={{ fontSize: "15px", verticalAlign: "text-top" }}/>
                  </span>
                )
                : (value / 1000).toFixed(4)}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [6, 12, 24, 48],
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    textLabels: {
      body: {
        noMatch: "Loading...",
      },
    },
  };

  return (
    <div>
      <MUIDataTable
        title={`Today (` + moment().format("DD MMM YYYY") + `)`}
        data={today}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Today;
