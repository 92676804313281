import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import styles from "./MonitoringComponents.module.scss";
import { tableNames, months } from "./Constants";
import CustomBarChart from "./CustomBarChart";

export default function BatteryTable(props) {
  const { selectedBattery } = props;
  const [modal, setModal] = useState(false);

  function toggleModal() {
    setModal(!modal);
  }

  // ----- Table Data -----
  const [batteryDetails, setBatteryDetails] = useState({});
  async function getTableDataWrapper() {
    const response = await axios.get(
      `/batteries/getBatteryTableData?batteryTableName=${tableNames[selectedBattery]}`
    );

    if (response.status === 200) {
      setBatteryDetails(convertArrayToObjects(response.data));
    }
  }
  const [lineChartData, setLineChartData] = useState([]);
  async function getLineChartDataWrapper() {
    const response = await axios.get(
      `/batteries/getPerformanceData?batteryTableName=${tableNames[selectedBattery]}`
    );

    if (response.status === 200) {
      var chargesum = 0;
      var dischargesum = 0;
      response.data.forEach((obj) => {
        if (Number(obj["value"]) < -2) {
          chargesum += Number(obj["value"]);
        } else if (Number(obj["value"]) > 2) {
          dischargesum += Number(obj["value"]);
        }
      });
      const resultArray = [
        { name: 'battery', charge: -(chargesum/120).toFixed(2), discharge: (dischargesum/120).toFixed(2) },
      ];
      console.log(resultArray);
      console.log(response.data);

      setLineChartData (resultArray);
    }
  }                                                         // new end

  function convertArrayToObjects(array) {
    const objects = {};
    for (const item of array) {
      objects[item.tag] = item.value;
    }

    // convert SOC to actual capacity
    objects["SOC"] = ((objects["Average SOC (%)"] / 100) * 271).toFixed(2);
    //varPriority: 0 = nothing critical,
    objects["PriorityLevel"] = varPriority[objects["varPriority"]];
    return objects;
  }

  const date = new Date(); //  current date and time
  const monthIndex = date.getMonth(); // This returns the month index (0-11)
  const currentMonthName = months[monthIndex];
  useEffect(() => {
    getTableDataWrapper();
    getLineChartDataWrapper();
  }, [selectedBattery]);

  let idleChargeDischarge = ["Idle", "Discharge", "Charge"];
  let varPriority = ["Non-Critical", "Critical"];
  // ----- End of Table Data -----
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>State of Charge</th>
            <th>Score</th>
            <th>Idle/Charging/Discharging</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{selectedBattery}</td>
            <td>
              {batteryDetails["Average SOC (%)"]}% - {batteryDetails["SOC"]}/271
              (kWh)
            </td>
            <td>{batteryDetails["score"]}</td>
            <td>{idleChargeDischarge[batteryDetails["CnD"]]}</td>

            <td>
              <Button color="primary" onClick={toggleModal}>
                View
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <CustomBarChart
        lineChartData={lineChartData}
        // lineChartData={getLineChartDataWrapper()}
      />
      <center><b> (kWh) </b><i>Since {currentMonthName} starts </i></center>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Details from PYC001BAT1</ModalHeader>
        <ModalBody>
          <div>
            <div className={styles.ModalFields}>
              <strong>Priority: </strong>
              <span>{batteryDetails["PriorityLevel"]}</span>
            </div>
            {/* todo -  this field is currently removed in the db */}
            {/* <div className={styles.ModalFields}>
              <strong>Days Last Serviced: </strong>
              <span>{batteryDetails["dls"]}</span>
            </div> */}

            <div className={styles.ModalFields}>
              <strong>Total Alarm Count: </strong>
              <span>{batteryDetails["totalCount"]}</span>
            </div>

            <div className={styles.ModalFields}>
              <strong>DLS Score: </strong>
              <span>{batteryDetails["dlsScore"]}</span>
            </div>

            <div className={styles.ModalFields}>
              <strong>Alarm Error Message: </strong>
              <span>
                {batteryDetails["alarmMessage"] === "{}"
                  ? "No Error"
                  : batteryDetails["alarmMessage"]}
              </span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
