export const batteryOptions = {
  "The Pulse": [
    {
      value: "The Pulse Battery 1",
      label: "The Pulse Battery 1",
      isFixed: true,
    },
  ],
  Asiagreen: [
    {
      value: "Asiagreen Battery 1",
      label: "Asiagreen Battery 1",
      isFixed: true,
    },
  ],
  "Enterprise One": [
    {
      value: "Enterprise One Battery 1",
      label: "Enterprise One Battery 1",
      isFixed: true,
    },
  ],
};
export const thingNames = {
  "The Pulse Battery 1": "pyc001_bat1",
  "Asiagreen Battery 1": "tpc001_bat1",
};

export const tableNames = {
  "The Pulse": "log_pyc001_ass1",
  Asiagreen: "log_tpc001_ass1",
  "Enterprise One": "log_kbm001_ass1",
  "The Pulse Battery 1": "log_pyc001_bat1",
  "Asiagreen Battery 1": "log_tpc001_bat1",
  // todo 07012025: change this battery name when Enterprise One Battery is deployed
  "Enterprise One Battery 1": "log_pyc001_bat1",
};

export const assetOptions = [
  {
    value: "The Pulse",
    label: "The Pulse",
    isFixed: true,
  },
  {
    value: "Asiagreen",
    label: "Asiagreen",
    isFixed: true,
  },
  {
    value: "Enterprise One",
    label: "Enterprise One",
    isFixed: true,
  },
];
export const dischargeRateOptions = {
  "The Pulse Battery 1": [
    { value: 10, label: 10, isFixed: true },
    { value: 20, label: 20, isFixed: true },
    { value: 30, label: 30, isFixed: true },
    { value: 40, label: 40, isFixed: true },
    { value: 50, label: 50, isFixed: true },
    { value: 60, label: 60, isFixed: true },
    { value: 70, label: 70, isFixed: true },
    { value: 80, label: 80, isFixed: true },
    { value: 90, label: 90, isFixed: true },
    { value: 100, label: 100, isFixed: true },
    { value: 110, label: 110, isFixed: true },
    { value: 120, label: 120, isFixed: true },
    { value: 130, label: 130, isFixed: true },
    { value: 140, label: 140, isFixed: true },
    { value: 150, label: 150, isFixed: true },
  ],
  "Asiagreen Battery 1": [
    { value: 10, label: 10, isFixed: true },
    { value: 20, label: 20, isFixed: true },
    { value: 30, label: 30, isFixed: true },
    { value: 40, label: 40, isFixed: true },
    { value: 50, label: 50, isFixed: true },
    { value: 60, label: 60, isFixed: true },
    { value: 70, label: 70, isFixed: true },
    { value: 80, label: 80, isFixed: true },
    { value: 90, label: 90, isFixed: true },
    { value: 100, label: 100, isFixed: true },
    { value: 110, label: 110, isFixed: true },
    { value: 120, label: 120, isFixed: true },
    { value: 130, label: 130, isFixed: true },
    { value: 140, label: 140, isFixed: true },
    { value: 150, label: 150, isFixed: true },
    { value: 160, label: 160, isFixed: true },
    { value: 170, label: 170, isFixed: true },
    { value: 180, label: 180, isFixed: true },
    { value: 190, label: 190, isFixed: true },
    { value: 200, label: 200, isFixed: true },
    { value: 210, label: 210, isFixed: true },
    { value: 220, label: 220, isFixed: true },
    { value: 230, label: 230, isFixed: true },
    { value: 240, label: 240, isFixed: true },
    { value: 250, label: 250, isFixed: true },
  ],
};
export const chargeBatteryDurationOptions = [
  {
    value: 5,
    label: 5,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 15,
    label: 15,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 25,
    label: 25,
  },
  {
    value: 30,
    label: "30 (0.5 Hour)",
  },
  {
    value: 35,
    label: 35,
  },
  {
    value: 40,
    label: 40,
  },
  {
    value: 45,
    label: 45,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 55,
    label: 55,
  },
  {
    value: 60,
    label: "60 (1 Hour)",
  },
  {
    value: 65,
    label: 65,
  },
  {
    value: 70,
    label: 70,
  },
  {
    value: 75,
    label: 75,
  },
  {
    value: 80,
    label: 80,
  },
  {
    value: 85,
    label: 85,
  },
  {
    value: 90,
    label: "90 (1.5 Hours)",
  },
  {
    value: 95,
    label: 95,
  },
  {
    value: 100,
    label: 100,
  },
  {
    value: 105,
    label: 105,
  },
  {
    value: 110,
    label: 110,
  },
  {
    value: 115,
    label: 115,
  },
  {
    value: 120,
    label: "120 (2 Hours)",
  },
];
export const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  
