import React from "react";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import axios from "axios";
import { thingNames } from "../Constants";

export default function IdleModal(props) {
  const { toggleIdleModal, idleModal, setBatteryDetails, batteryName } = props;
  async function handleConfirmIdle() {
    // Close modal
    toggleIdleModal();
    // Send message to AWS IoT Core to charge
    const message = { thingName: thingNames[batteryName] };
    const publishIdleSettings = await axios.post(
      "/batteries/publishIdleSettings",
      message
    );
    const response = await axios.get("/charge_settings");
    if (response.status === 200) {
      setBatteryDetails(response.data);
    }
  }
  return (
    <Modal isOpen={idleModal} toggle={toggleIdleModal}>
      <ModalHeader toggle={toggleIdleModal}>
        Confirm Idling of {batteryName}?
      </ModalHeader>

      <ModalFooter>
        <Button color="secondary" onClick={handleConfirmIdle}>
          Confirm Idle
        </Button>
      </ModalFooter>
    </Modal>
  );
}
